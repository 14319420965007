/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-25 17:44:07
 * @Module: 邀约管理列表
 */
 <template>
  <div>
    <div class="header">
      <h6>邀约管理</h6>
    </div>
    <el-card style="margin-top:20px">
      <el-row :gutter="20">
        <el-form :inline="true" :size="'small'" :model="formInline" class="demo-form-inline">
          <el-col :span="6">
            <el-form-item label="经纪人:">
              <brokerSelect
                @selectTreeChange="selectTreeChange"
                @clearBrokerSelect="clearBrokerSelect"
                placeholder="请选择经纪人"
              ></brokerSelect>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="邀约状态:">
              <el-select v-model="formInline.invite_state" placeholder="选择要查询的邀约状态" clearable>
                <el-option
                  v-for="item in inviteStateOption"
                  :key="item.key"
                  :label="item.title"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="req_list(true)">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <tablePage
        :tableData="tableData"
        :currentPage="formInline.page"
        :height="tableHeight"
        @current-change="currentChange"
        v-loading="loading"
        :total="total"
        id="table"
        ref="tableList"
      >
        <el-table-column label="主播信息" min-width="300">
          <template slot-scope="scope">
            <div class="base-wrap">
              <el-avatar :size="64" :src="scope.row.avatar"></el-avatar>
              <div>
                <p>{{ scope.row.name }}</p>
                <p>快手号：{{ scope.row.tick_account }}</p>
                <p>电话：{{ scope.row.phone }}</p>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="其他信息" min-width="180">
          <template slot-scope="scope">
            <p>分成比：{{ scope.row.separate_ratio }}%</p>
            <p>经纪人：{{ scope.row.broker_name }}</p>
            <el-popover width="200" trigger="click" @hide="req_updateTickId(scope.row)"  v-if="role('administrator')">
              <el-input size="mini" v-model="newTick_id" placeholder="请输入快手ID"></el-input>
              <p slot="reference" style="cursor: pointer;">
                快手ID：{{ scope.row.tick_id||"--" }} &nbsp;
                <i
                  style="color:#FF3B00"
                  class="el-icon-edit"
                ></i>
              </p>
            </el-popover>
            <p v-else> 快手ID：{{ scope.row.tick_id||"--" }}</p>
          </template>
        </el-table-column>
        <el-table-column label="邀约状态" min-width="180">
          <template slot-scope="scope">
            <el-select
              :value="scope.row.invite_state"
              @change="(value)=>req_updateInviteState(value,scope.row)"
              size="mini"
              v-if="role('administrator')"
            >
              <el-option
                v-for="item in inviteStateList"
                :key="item.key"
                :label="item.title"
                :value="item.value"
              ></el-option>
            </el-select>
            <div v-else>{{scope.row.invite_state_string}}</div>
          </template>
        </el-table-column>

        <el-table-column min-width="100" label="刷新时间">
          <template slot-scope="scope">
            <p style="width:80px;">{{ scope.row.updated_at }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="100" label="首次邀约时间">
          <template slot-scope="scope">
            <p style="width:80px;">{{ scope.row.created_at }}</p>
          </template>
        </el-table-column>

        <el-table-column label="操作" v-if="!role('personal')">
          <template slot-scope="scope">
            <el-button type="text" @click="req_sendMessage(scope.row)">重发私信</el-button>
          </template>
        </el-table-column>
      </tablePage>
    </el-card>
  </div>
</template>
 <script>
//  document.body.clientHeight
import tablePage from "components/tablePage";
import brokerSelect from "components/brokerSelect";
import tableHeight from "mixins/tableHeight";
import api from "@/api";
export default {
  data() {
    return {
      loading: true,
      formInline: {
        broker_id: null, //经纪人
        invite_state: null, // 邀约状态
        page: 1,
      },
      tableData: [],
      total: 10,
      inviteStateOption: [], //筛选的状态列表
      inviteStateList: [], //列表中的状态列表
      newTick_id: "", //更改的快手ID
    };
  },
  components: {
    tablePage,
    brokerSelect,
  },
  mixins: [tableHeight],
  created() {
    this.req_list(true);
    this.req_inviteStateOption();
    this.req_inviteStateList();
  },
  mounted() {},
  methods: {
    async _initState(params = {}) {
      this.loading = true;
      const {
        status_code,
        meta: {
          pagination: { total },
        },
        message,
      } = await api.inviteListApi(params);
      // console.log('data', status_code, total, message);
      if (status_code === 200) {
        this.total = total;
        this.tableData = message;
        this.loading = false;
      }
    },
    currentChange(page) {
      this.formInline.page = page;
      this.req_list();
    },
    // 重发私信
    async req_sendMessage(item) {
      const { anchor_id } = item;
      const { status_code, message } = await api.sendMessage({
        anchor_id,
      });
      if (status_code === 200) {
        this.$message.success(message);
      } else {
        this.$message.error(message);
      }
    },
    // 选择经纪人回调
    selectTreeChange(id) {
      this.formInline.broker_id = id;
    },
    // 清除选择经纪人回调
    clearBrokerSelect() {
      this.formInline.broker_id = null;
    },
    // 请求列表
    req_list(refresh) {
      if (refresh) this.formInline.page = 1;
      this._initState(this.formInline);
    },
    // 请求筛选的邀约状态列表
    async req_inviteStateOption() {
      const { status_code, message } = await api.inviteStateOption();
      if (status_code === 200) {
        this.inviteStateOption = message;
      }
    },
    // 请求邀约管理中的邀约状态列表
    async req_inviteStateList() {
      const { status_code, message } = await api.inviteStateList();
      if (status_code === 200) {
        this.inviteStateList = message;
      }
    },
    // 更新邀约状态
    async req_updateInviteState(invite_state, item) {
      const { anchor_id } = item;
      const { status_code, message } = await api.updateInviteState({
        anchor_id,
        invite_state,
      });
      if (status_code === 200) {
        const list = [...this.tableData];
        list.forEach((item, index) => {
          if (item.anchor_id == anchor_id)
            list[index].invite_state = invite_state;
        });
        this.tableData = list;
        this.$message.success(message);
      }
    },
    // 快手ID更改
    async req_updateTickId(item) {
      const { anchor_id } = item;
      if (!this.newTick_id) return;
      const { status_code, message } = await api.updateTickId({
        anchor_id,
        tick_id: this.newTick_id,
      });
      if (status_code === 200) {
        const list = [...this.tableData];
        list.forEach((item, index) => {
          if (item.anchor_id == anchor_id)
            list[index].tick_id = this.newTick_id;
        });
        this.tableData = list;
        this.newTick_id = "";
        this.$message.success(message);
      }
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
@import "./style.less";
</style>